<template>
	<section class="case-name">
		<h1>
			{{ project.name }}
		</h1>
		<p>
			{{ project.description }}
		</p>
		<div @click="$router.go(-1)" class="back">
			←
		</div>
	</section>
	<section class="photos">
		<template v-for="(p, k) in project.photos" :key="k">
			<video v-if="p.video" :src="p.file" controls />
			<img v-else :src="p.file" alt="" />
		</template>
	</section>
	<router-link
		:to="{ path: '/Contacts', hash: '#questions' }"
		class="send-req"
	>
		{{ $t("sendRequest") }}
	</router-link>
	<section class="other-cases">
		<h2>{{ $t("otherCase") }}</h2>
		<all-cases :cases="filteredCases"></all-cases>
	</section>
</template>

<script>
import AllCases from "../../components/AllCases.vue";
import { http } from "../../components/Services/httpService";
export default {
	components: { AllCases },
	props: {
		cases: {
			default: () => [],
		},
	},
	data() {
		return {
			project: {
				name: "",
				description: "",
				photos: [],
			},
			filteredCases: [],
		};
	},
	methods: {
		saveData() {
			const locale = localStorage.getItem("locale");
			const parseImg = (img) => {
				let splitted = img.url.split(".");
				return {
					file: img.url,
					video:
						splitted[splitted.length - 1] === "mp4" ||
						splitted[splitted.length - 1] === "mpeg" ||
						splitted[splitted.length - 1] === "webm" ||
						splitted[splitted.length - 1] === "avi",
				};
			};
			http.get(
				`/api/cases/${this.$route.params.id}?lang=${
					locale ? locale : "ru-RU"
				}`
			).then((response) => {
				if (response.status < 400) {
					console.log(response.data);
					const block = response.data.blocks.find(
						(e) => e.name === "Keys"
					);
					this.project = {
						...response.data,
						photos: block.data.Foto_keysa.map((i) => parseImg(i)),
					};
					this.filteredCases = this.cases.filter(
						(c) => c.name !== this.project.name
					);
				}
			});
		},
	},
	mounted() {
		console.log(true);
		this.saveData();
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";

.case-name {
	height: ptr(97px);
	justify-self: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	text-align: center;
	h1 {
		font-size: ptr(34px);
		text-transform: uppercase;
		margin: 0 0 ptr(5px);
	}
	p {
		font-size: ptr(14px);
		color: #646464;
		font-family: $sub-font;
	}
	.back {
		position: absolute;
		font-family: $sub-font;
		left: ptr(220px);
		top: ptr(32px);
		font-size: ptr(28px);
		font-weight: 500;
		cursor: pointer;
	}
}

.photos {
	margin-top: ptr(24px);
	justify-self: center;
	display: grid;
	grid-template-columns: repeat(2, max-content);
	gap: ptr(24px) ptr(22px);
	margin-bottom: ptr(100px);
	img,
	video {
		width: ptr(1468px);
		height: ptr(789px);
		grid-column: span 2;
		object-fit: cover;
		border-radius: ptr(104px);
		&:nth-child(3),
		&:nth-child(4) {
			width: ptr(723px);
			height: ptr(452px);
			grid-column: span 1;
		}
	}
}

.send-req {
	width: ptr(300px);
	border-radius: ptr(100px);
	text-transform: uppercase;
	border: 2px solid #882b02;
	display: flex;
	justify-self: center;
	justify-content: center;
	padding: ptr(13px) ptr(15px);
	font-size: ptr(19px);
	background: #882b02;
	color: white;
	cursor: pointer;
}

@media (max-width: 1024px) {
	.case-name {
		height: unset;
		margin-top: ptr(140px);
		h1 {
			font-size: ptr(23px);
			margin: 0 0 ptr(5px);
		}
		p {
			font-size: ptr(12px);
		}
		.back {
			display: none;
		}
	}

	.photos {
		margin-top: ptr(18px);
		grid-template-columns: repeat(1, max-content);
		gap: ptr(8px);
		margin-bottom: ptr(70px);
		img,
		video {
			width: ptr(306px);
			height: ptr(162px);
			grid-column: span 1;
			border-radius: ptr(34px);
			&:nth-child(3),
			&:nth-child(4) {
				width: ptr(306px);
				height: ptr(162px);
			}
		}
	}
	.send-req {
		width: ptr(200px);
		font-size: ptr(13px);
		margin-bottom: ptr(30px);
	}
}
</style>
