<template>
	<section class="phrase">
		<p>Worldwide event production</p>
		<h1 v-html="$t('main.h1')"></h1>
		<video src="/static/video/mag.mp4" autoplay muted loop></video>
	</section>
	<all-cases :cases="cases" />
</template>

<script>
import { ScrollAnimate } from "@/components/Services/ScrollAnimationService";
import AllCases from "../../components/AllCases.vue";
export default {
	components: { AllCases },
	props: {
		cases: {
			default: () => [],
		},
	},
	data() {
		return {
			animate: new ScrollAnimate(),
		};
	},
	watch: {
		cases(newVal) {
			if (newVal.length > 0) {
				this.$nextTick(() => {
					for (const k in this.$refs) {
						this.animate.setObserverFunction([
							{
								name: "#" + k,
								animationName: "fade",
							},
						]);
					}
				});
			}
		},
	},
	mounted() {
		if (this.cases.length > 0) {
			this.$nextTick(() => {
				for (const k in this.$refs) {
					this.animate.setObserverFunction([
						{
							name: "#" + k,
							animationName: "fade",
						},
					]);
				}
			});
		}
		this.animate.init();
	},
};
</script>

<style lang="scss">
.clr {
	color: #e2531c;
}
</style>

<style lang="scss" scoped>
@import "@/styles/config.scss";

.phrase {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: ptr(804px);
	position: relative;
	overflow: hidden;
	p {
		color: #adadad;
		font-size: ptr(14px);
		font-family: $sub-font;
	}
	video {
		width: 100vw;
		left: 0;
		top: 0;
		opacity: 0.4;
		z-index: -1;
		position: absolute;
	}
	&::after {
		content: "";
		width: 100%;
		height: 40%;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 1;
		background: linear-gradient(rgba(0, 0, 0, 0), black);
	}
}
h1 {
	margin: ptr(14px) 0 0;
	text-align: center;
	font-size: ptr(50px);
	text-transform: uppercase;
}

@media (max-width: 1024px) {
	.phrase {
		height: ptr(409px);
		p {
			font-size: ptr(12px);
		}
		video {
			height: 100%;
			object-fit: cover;
		}
	}
	h1 {
		margin: ptr(10px) 0 0;
		font-size: ptr(23px);
	}
}
</style>
