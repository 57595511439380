<template>
	<router-link :to="'/Case' + data.url" class="case">
		<div
			class="image-container"
			@mouseleave="leave"
			@mouseenter="checkPosition($event)"
			@mousemove="move"
			:style="rotate"
		>
			<!-- <div
				class="cc"
				:style="`width: ${circle.x}px; height: ${circle.y}px`"
			></div> -->
			<img :src="data.image" alt="" />
		</div>
		<div class="name">{{ data.name }}</div>
		<p>{{ data.description }}</p>
	</router-link>
</template>

<script>
export default {
	props: {
		data: {
			default: () => ({}),
		},
	},
	data() {
		return {
			hover: false,
			idle: true,
			circle: {
				x: 0,
				y: 0,
			},
			rectHeight: null,
			mousePos: {
				x: 0,
				y: 0,
			},
			el: {
				height: 0,
				width: 0,
			},
			rotateMode: true,
		};
	},
	methods: {
		mainLoop() {
			if (this.idle) return;
			const targetPos = this.mousePos;
			let dirVector = {
				x: targetPos.x - this.circle.x,
				y: targetPos.y - this.circle.y,
			};
			dirVector = {
				x: dirVector.x * 0.1,
				y: dirVector.y * 0.1,
			};
			this.circle.x += dirVector.x;
			this.circle.y += dirVector.y;
			const norm = Math.sqrt(
				dirVector.x * dirVector.x + dirVector.y * dirVector.y
			);
			if (!this.hover && norm < 0.2) {
				this.idle = true;
				this.circle = targetPos;
			}
			requestAnimationFrame(this.mainLoop);
		},
		checkPosition(e) {
			this.rectHeight = e.target.getBoundingClientRect();
			this.hover = true;
			this.idle = false;
			this.mainLoop();
		},
		move(e) {
			const rotate = this.rotateMode;
			const x = rotate ? e.layerX : this.rectHeight.width / 2 - e.offsetX;
			const y = rotate
				? e.layerY
				: this.rectHeight.height / 2 - e.offsetY;
			const n = rotate ? 1 : 2.5;
			this.mousePos = {
				x: x / n,
				y: y / n,
			};
		},
		leave() {
			this.hover = false;
			if (this.rotateMode) {
				this.mousePos = {
					x: this.el.width / 2,
					y: this.el.height / 2,
				};
			} else {
				this.mousePos = {
					x: 0,
					y: 0,
				};
			}
		},
	},
	computed: {
		rotate() {
			const w = this.el.width;
			const h = this.el.height;
			const cX = this.circle.x;
			const cY = this.circle.y;
			const x = 10 * ((cX - w / 2) / w);
			const y = -10 * ((cY - h / 2) / h);
			return `transform: perspective(500px) scale(${
				this.hover ? 1.02 : 1
			}) rotateX(${y}deg) rotateY(${x}deg)`;
		},
	},
	mounted() {
		this.el.height = this.$el.scrollHeight;
		this.el.width = this.$el.scrollWidth;
		this.circle = {
			x: this.el.width / 2,
			y: this.el.height / 2,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/config.scss";

// .cc {
// 	position: absolute;
// 	left: 0;
// 	top: 0;
// 	background: red;
// 	z-index: 10;
// }

.case {
	width: ptr(700px);
	height: ptr(440px);
	font-family: $sub-font;
	text-align: center;
	position: relative;
	.name {
		font-size: ptr(20px);
		text-transform: uppercase;
		font-weight: 500;
		margin: ptr(18px) 0 ptr(8px);
	}
	p {
		font-size: ptr(14px);
		color: rgba(255, 255, 255, 0.6);
	}
}

.image-container {
	width: ptr(700px);
	height: ptr(370px);
	border-radius: ptr(80px);
	background: rgb(20, 20, 20);
	overflow: hidden;
	transition: 0.1s $anmTrs;
	box-shadow: 0 0 ptr(2px) ptr(1px) rgba(255, 255, 255, 0.2);
	img {
		object-fit: cover;
		pointer-events: none;
		width: 100%;
		height: 100%;
	}
}

@media (max-width: 1024px) {
	.case {
		width: ptr(306px);
		height: unset;
		.name {
			font-size: ptr(14px);
			margin: ptr(10px) 0 ptr(2px);
		}
		p {
			font-size: ptr(12px);
		}
	}
	.image-container {
		width: ptr(306px);
		height: ptr(162px);
		border-radius: ptr(34px);
	}
}
</style>
