<template>
	<section class="phrase">
		<h1>
			{{ $t("pages.clients") }}
		</h1>
	</section>
	<section class="clients">
		<div
			v-for="(c, k) in data.Klienty.Spisok_brendov"
			:key="k"
			class="client"
		>
			<svg viewBox="0 0 349 349" fill="none">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M193.834 1.01294C237.919 1.01294 260.89 6.95651 281.828 18.1543C302.717 29.3262 319.111 45.7205 330.283 66.6101C341.481 87.5482 347.425 110.519 347.425 154.604V194.097C347.425 238.182 341.481 261.153 330.283 282.091C319.111 302.98 302.717 319.375 281.828 330.546C260.89 341.744 237.919 347.688 193.834 347.688H154.341C110.256 347.688 87.2852 341.744 66.3471 330.546C45.4576 319.375 29.0633 302.98 17.8914 282.091C6.69362 261.153 0.75 238.182 0.75 194.097V154.604C0.75 110.519 6.69362 87.5482 17.8914 66.6101C29.0633 45.7205 45.4576 29.3262 66.3471 18.1543C87.2852 6.95651 110.256 1.01294 154.341 1.01294H193.834Z"
				/>
			</svg>
			<img :src="c" alt="" />
		</div>
	</section>
	<section class="stars">
		<h2>
			{{ $t("stars") }}
		</h2>
		<tags :data="data.Klienty.Spisok_tegov" :white="true"></tags>
	</section>
	<section class="other-cases">
		<h2>{{ $t("case") }}</h2>
		<all-cases :cases="cases"></all-cases>
	</section>
	<div class="back-gradient"></div>
</template>

<script>
import Tags from "../../components/Tags.vue";
import { ScrollAnimate } from "@/components/Services/ScrollAnimationService.js";
import AllCases from "../../components/AllCases.vue";
export default {
	components: { Tags, AllCases },
	props: {
		data: {
			default: () => ({}),
		},
		cases: {
			default: () => [],
		},
	},
	data() {
		return {
			clients: [],
			stars: [],
			animate: new ScrollAnimate(),
		};
	},
	mounted() {
		this.animate.init();
		this.animate.setObserverFunction([
			{
				name: ".clients",
				animationName: "fade",
				delay: 500,
			},
			{
				name: ".stars",
				animationName: "fade",
			},
		]);
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";

.phrase {
	height: ptr(450px);
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}
h1 {
	font-size: ptr(50px);
	margin: 0;
	text-transform: uppercase;
}

.clients {
	justify-self: center;
	display: grid;
	grid-template-columns: repeat(6, max-content);
	margin-top: ptr(198px);
	gap: ptr(7px);
	opacity: 0;
}

.client {
	display: flex;
	align-items: center;
	justify-content: center;
	width: ptr(215px);
	height: ptr(215px);
	position: relative;
	img {
		width: ptr(120px);
		height: ptr(83px);
		object-fit: contain;
		object-position: center;
	}
}

svg {
	position: absolute;
	width: 100%;
	height: 100%;
	path {
		x: 1;
		y: 1;
		width: ptr(215px);
		height: ptr(215px);
		transition: $trs;
		stroke-width: 1.5;
		stroke: rgba(255, 255, 255, 0.28);
	}
}

.stars {
	margin: ptr(126px) 0 0;
	justify-self: center;
	display: grid;
	justify-content: center;
	position: relative;
	opacity: 0;
	h2 {
		font-size: ptr(34px);
		justify-self: center;
		text-transform: uppercase;
		margin: 0 0 ptr(91px);
	}
}

.back-gradient {
	height: ptr(1283px);
}

@media (max-width: 1024px) {
	.phrase {
		height: ptr(318px);
	}
	h1 {
		font-size: ptr(23px);
	}

	.clients {
		grid-template-columns: repeat(2, max-content);
		margin-top: ptr(141px);
		width: ptr(320px);
		gap: ptr(0px);
	}

	.client {
		width: ptr(160px);
		height: ptr(160px);
		img {
			width: ptr(60px);
			height: ptr(53px);
		}
	}

	.stars {
		width: ptr(320px);
		margin: ptr(126px) 0 ptr(107px);
		h2 {
			font-size: ptr(20px);
			margin: 0 0 ptr(41px);
		}
		&::before {
			top: ptr(52px);
			left: ptr(21px);
			width: ptr(277px);
		}
	}

	.back-gradient {
		height: ptr(1283px);
	}
}
</style>
