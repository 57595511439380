<template>
	<side-menu></side-menu>
	<router-view v-slot="{ Component }">
		<transition name="fade" mode="out-in">
			<main :key="$route.fullPath">
				<Component
					:is="Component"
					:data="data"
					:cases="cases"
				></Component>
			</main>
		</transition>
	</router-view>
	<footer>
		<img src="/static/icons/logo.svg" alt="" />
		<p class="part-of">
			Part of
			<a href="https://placebo25.com/" target="_blank">PLACEBO/25</a>
		</p>
		<p class="rights">ООО «МАГНИТОФОН» © 2016—2021 {{ $t("rights") }}</p>
		<PolicyModal
			v-if="showPolicy"
			ref="policy"
			id="plc"
			@close="closePolicy"
		></PolicyModal>
		<div class="documents">
			<p>ИНН: 7725371693 <span>/</span></p>
			<p>ОГРН: 1177746444875 <span>//</span></p>
			<p class="policy" @click="openPolicy">{{ $t("policy") }}</p>
		</div>
	</footer>
</template>

<script>
import SideMenu from "./components/SideMenu.vue";
import CustomScroll from "./components/Services/CustomScroll";
import { http } from "./components/Services/httpService";
import PolicyModal from "./components/PolicyModal.vue";
export default {
	components: { SideMenu, PolicyModal },
	data() {
		return {
			data: {},
			cases: [],
			showPolicy: false,
		};
	},
	methods: {
		getAllData() {
			http.get("/api/pages/data?lang=" + this.$i18n.locale).then(
				(response) => {
					if (response.status < 400) {
						for (let b of response.data.blocks) {
							this.data[b.name] = b.data;
						}
					}
				}
			);
		},
		getCases() {
			http.get("/api/cases?lang=" + this.$i18n.locale).then(
				(response) => {
					this.cases = response.data.cases;
				}
			);
		},
		openPolicy() {
			document.body.classList.add("scroll-stop");
			this.showPolicy = true;
			CustomScroll.disable();
		},
		closePolicy() {
			document.body.classList.remove("scroll-stop");
			this.showPolicy = false;
			CustomScroll.init();
		},
        initFbq() {
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');

            window.fbq('init', '400060968430293');
            window.fbq('track', 'PageView');
        }
	},
	mounted() {
		this.getAllData();
		this.getCases();
		CustomScroll.init();

        if (process.env.NODE_ENV === "production") {
            this.initFbq();
        }
	},
};
</script>

<style lang="scss">
@import "./styles/config.scss";

main {
	display: grid;
	width: 100%;
	color: $text-color;
	min-height: 100vh;
}

html {
	background: black;
}

body {
	height: 100%;
	width: 100%;
	margin: 0;
}

.scroll-stop {
	overflow: hidden;
}

#app {
	display: grid;
	position: relative;
	overflow: hidden;
	font-family: $main-font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.back-gradient {
	position: absolute;
	height: ptr(2092px);
	width: 100%;
	bottom: ptr(-341px);
	background: linear-gradient(180deg, #000000 0%, #942d00 70.28%);
	z-index: -1;
}

.fade-enter-active {
	animation: fade 0.7s ease-in-out;
}
.fade-leave-active {
	animation: fade 0.7s ease-in-out reverse;
}

.other-cases {
	margin: ptr(126px) 0 ptr(100px);
	position: relative;
	justify-self: center;
	h2 {
		font-size: ptr(34px);
		text-align: center;
		text-transform: uppercase;
		margin: 0 0 ptr(80px);
	}
}

.thanks {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	top: 0;
	font-size: ptr(50px);
	text-transform: uppercase;
	z-index: 100;
	p {
		white-space: pre;
	}
	span {
		opacity: 0;
		display: inline-block;
	}
}

@media (max-width: 1024px) {
	.other-cases {
		margin: ptr(60px) 0 ptr(90px);
		h2 {
			font-size: ptr(23px);
			margin: 0 0 ptr(48px);
		}
	}
	.thanks {
		font-size: ptr(30px);
		p {
			text-align: center;
			white-space: pre-wrap;
		}
	}
}
</style>

<style lang="scss" scoped>
@import "./styles/config.scss";
footer {
	justify-self: center;
	display: grid;
	justify-items: center;
	width: ptr(1423px);
	padding: ptr(84px) 0 ptr(114px);
	position: relative;
	font-family: $sub-font;
	color: #8d8d8d;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: ptr(1423px);
		height: 1px;
		background: rgba(255, 255, 255, 0.3);
	}
}

.part-of {
	margin: ptr(26px) 0 ptr(42px);
	color: white;
	a {
		color: white;
	}
}

.rights {
	margin-bottom: ptr(14px);
	color: rgba(255, 255, 255, 0.57);
}

.documents {
	display: grid;
	grid-template-columns: repeat(3, max-content);
	gap: ptr(5px);
	p {
		color: rgba(255, 255, 255, 0.27);
	}
}

.policy {
	cursor: pointer;
}

@media (max-width: 1024px) {
	footer {
		width: 100%;
		padding: 0 0 ptr(108px);
		&::before {
			content: unset;
		}
	}

	.part-of {
		margin: ptr(19px) 0 ptr(10px);
		font-size: ptr(12px);
	}

	.rights {
		margin-bottom: ptr(12px);
		text-align: center;
		font-size: ptr(12px);
	}

	.documents {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: ptr(10px);
		gap: ptr(2px) ptr(10px);
		width: 80%;
		p {
			font-size: ptr(10px);
			span {
				display: none;
			}
		}
	}
}
</style>
