import { useMetrika } from "./components/metrika/helpers";
import { useGtag } from "vue-gtag-next";

export function reachGoal(event, options = null) {
	// const { $metrika: metrika, $gtag: gtag } = new Vue();
	const metrika = useMetrika();
	const gtag = useGtag();

	metrika.reachGoal(event);
	gtag.event(event, {
		"event_category": options?.category || event
	});
	window.fbq("trackCustom", event);
}
