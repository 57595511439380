<template>
	<section class="phrase about">
		<h1 v-html="$t('about.h1')"></h1>
	</section>
	<section class="circles">
		<div class="circle min">
			<p v-html="$t('about.circles[0]')"></p>
		</div>
		<div class="circle mid">
			<p v-html="$t('about.circles[1]')"></p>
		</div>
		<div class="circle max">
			<p v-html="$t('about.circles[2]')"></p>
		</div>
	</section>
	<section class="partners">
		<h2>{{ $t("about.generalParters") }}</h2>
		<div class="partners-list">
			<div v-for="(p, k) in partners" :key="k" class="partner">
				<svg viewBox="0 0 349 349" fill="none">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M193.834 1.01294C237.919 1.01294 260.89 6.95651 281.828 18.1543C302.717 29.3262 319.111 45.7205 330.283 66.6101C341.481 87.5482 347.425 110.519 347.425 154.604V194.097C347.425 238.182 341.481 261.153 330.283 282.091C319.111 302.98 302.717 319.375 281.828 330.546C260.89 341.744 237.919 347.688 193.834 347.688H154.341C110.256 347.688 87.2852 341.744 66.3471 330.546C45.4576 319.375 29.0633 302.98 17.8914 282.091C6.69362 261.153 0.75 238.182 0.75 194.097V154.604C0.75 110.519 6.69362 87.5482 17.8914 66.6101C29.0633 45.7205 45.4576 29.3262 66.3471 18.1543C87.2852 6.95651 110.256 1.01294 154.341 1.01294H193.834Z"
					/>
				</svg>
				<img
					:src="p.icon"
					alt=""
					:style="`transform: scale(${p.scale})`"
				/>
				<p>
					{{ p.description }}
				</p>
			</div>
		</div>
	</section>
	<section class="tags">
		<tags :data="data.O_nas.Spisok_tegov" :cv="true"></tags>
	</section>
	<section class="other-cases">
		<h2>{{ $t("case") }}</h2>
		<all-cases :cases="cases"></all-cases>
	</section>
</template>

<script>
import { ScrollAnimate } from "@/components/Services/ScrollAnimationService";
import Tags from "../../components/Tags.vue";
import AllCases from "../../components/AllCases.vue";
export default {
	components: { Tags, AllCases },
	props: {
		data: {
			default: () => ({}),
		},
		cases: {
			default: () => [],
		},
	},
	data() {
		return {
			partners: [
				{
					icon: "/static/icons/tod.svg",
					description: "Концертная\nплощадка",
					scale: 1,
				},
				{
					icon: "/static/icons/loft-hall.svg",
					description: "Пространства для\nмероприятий",
					scale: 1,
				},
				{
					icon: "/static/icons/placebo.svg",
					description: "Концертное\nагентство",
					scale: 1.3,
				},
				{
					icon: "/static/icons/meduza.svg",
					description: "Серия музыкальных\nперфомансов",
					scale: 1,
				},
			],
			animate: new ScrollAnimate(),
		};
	},
	mounted() {
		this.animate.init();
		this.animate.setObserverFunction([
			{
				name: ".circles",
				animationName: "fade",
			},
			{
				name: ".partners",
				animationName: "fade",
			},
			{
				name: ".tags",
				animationName: "fade",
			},
		]);
	},
};
</script>

<style lang="scss">
@import "@/styles/config.scss";

.about h1 span {
	position: relative;
	border-radius: ptr(100px);
	padding: 0 ptr(20px);
	&::before {
		content: "";
		width: ptr(55px);
		height: ptr(60px);
		position: absolute;
		background-repeat: no-repeat;
		background-size: contain;
	}
}
.audio {
	border: 1px solid #e2531c;
	color: #e2531c;
	&::before {
		background-image: url("/static/icons/camera.svg");
		left: ptr(170px);
		top: ptr(-37px);
		transform: rotate(-26deg);
	}
}
.tech {
	border: 1px solid #969696;
	color: #969696;
	&::before {
		background-image: url("/static/icons/gear.svg");
		right: ptr(70px);
		top: ptr(-32px);
	}
}
.heavy {
	font-weight: 600;
}

@media (max-width: 1024px) {
	.about h1 span {
		display: inline-flex;
		padding: 0 ptr(10px);
		&::before {
			width: ptr(23px);
			height: ptr(23px);
		}
	}
	.audio {
		&::before {
			left: ptr(234px);
			top: ptr(-15px);
			transform: rotate(-24deg);
		}
	}
	.tech {
		&::before {
			left: ptr(-10px);
			top: ptr(0px);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "@/styles/config.scss";

.phrase {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: ptr(804px);
	p {
		color: #adadad;
		font-size: ptr(14px);
		font-family: $sub-font;
	}
	h1 {
		margin: ptr(14px) 0 0;
		text-align: center;
		line-height: ptr(49px);
		font-size: ptr(34px);
		text-transform: uppercase;
	}
}
.circles {
	position: relative;
	display: flex;
	justify-self: center;
	flex-direction: column;
	align-items: center;
	height: ptr(943px);
	opacity: 0;
}

.circle {
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	p {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		font-size: ptr(18px);
		font-family: $sub-font;
		text-transform: uppercase;
		text-align: center;
	}
	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		// box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.17);
	}
}

.min {
	width: ptr(351px);
	height: ptr(351px);
	&::before {
		background: linear-gradient(180deg, #481904 0%, #050505);
		transform: rotate(-135deg);
		background-repeat: no-repeat;
	}
}
.mid {
	width: ptr(507px);
	height: ptr(507px);
	top: ptr(-200px);
	right: ptr(-308px);
	z-index: 2;
	&::before {
		transform: rotate(-51deg);
		background: linear-gradient(180deg, #481904 0%, #050505);
		background-repeat: no-repeat;
	}
}
.max {
	width: ptr(656px);
	height: ptr(656px);
	top: ptr(-572px);

	right: ptr(111px);
	&::before {
		background: linear-gradient(180deg, #481904 0%, #050505);
		background-repeat: no-repeat;
	}
}

.partners {
	justify-self: center;
	margin: ptr(210px) 0 0;
	opacity: 0;
	h2 {
		font-size: ptr(32px);
		margin: 0 0 ptr(105px);
		text-transform: uppercase;
		text-align: center;
	}
}

.partners-list {
	display: grid;
	grid-template-columns: repeat(4, max-content);
	gap: ptr(11px);
}

.partner {
	border-radius: ptr(128px);
	width: ptr(348px);
	height: ptr(348px);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: $trs;
	position: relative;
	p {
		position: absolute;
		color: #646464;
		text-align: center;
		text-transform: uppercase;
		bottom: ptr(40px);
		font-family: $sub-font;
		font-size: ptr(14px);
		line-height: ptr(18px);
		white-space: pre;
	}
	svg {
		position: absolute;
		width: 100%;
		height: 100%;
		path {
			x: 1;
			y: 1;
			width: ptr(347px);
			height: ptr(347px);
			transition: $trs;
			stroke-width: 1.5;
			stroke: #404040;
		}
	}
	img {
		width: ptr(94px);
		height: ptr(92px);
		object-fit: contain;
	}
	&:hover {
		path {
			stroke: #c23d03;
		}
	}
}

.tags {
	margin-top: ptr(313px);
	justify-self: center;
	opacity: 0;
}

@media (max-width: 1024px) {
	.phrase {
		height: ptr(334px);
		margin-top: ptr(100px);
		h1 {
			line-height: ptr(19px);
			font-size: ptr(16px);
		}
	}
	.circles {
		height: ptr(434px);
	}

	.circle {
		flex-shrink: 0;
		p {
			font-size: ptr(12px);
		}
	}

	.min {
		width: ptr(204px);
		height: ptr(204px);
		right: ptr(20px);
	}
	.mid {
		width: ptr(205px);
		height: ptr(205px);
		top: ptr(-90px);
		right: ptr(-90px);
	}
	.max {
		width: ptr(265px);
		height: ptr(265px);
		top: ptr(-230px);
		right: ptr(90px);
	}

	.partners {
		margin: ptr(107px) 0 0;
		h2 {
			font-size: ptr(23px);
			margin: 0 0 ptr(67px);
		}
	}

	h2 {
		grid-column: unset;
	}

	.partners-list {
		display: grid;
		grid-template-columns: repeat(2, max-content);
		gap: 0;
	}

	.partner {
		border-radius: ptr(53px);
		width: ptr(160px);
		height: ptr(160px);
		p {
			bottom: ptr(13px);
			font-size: ptr(10px);
			line-height: ptr(12px);
		}
		svg {
			position: absolute;
			width: 100%;
			height: 100%;
			path {
				width: ptr(160px);
				height: ptr(160px);
			}
		}
		img {
			width: ptr(55px);
			height: ptr(55px);
		}
	}

	.tags {
		margin-top: ptr(123px);
		width: 100%;
		overflow: scroll;
	}
}
</style>
