import { createRouter, createWebHistory } from "vue-router";
import MainPage from "../views/Main/MainPage.vue";
import AboutPage from "../views/About/AboutPage.vue";
import CasePage from "../views/Case/CasePage.vue";
import CaseGroupPage from "../views/CaseGroup/CaseGroupPage.vue";
import ClientsPage from "../views/Clients/ClientsPage.vue";
import ContactsPage from "../views/Contacts/ContactsPage.vue";
import EquipmentPage from "../views/Equipment/EquipmentPage.vue";
import TeamPage from "../views/Team/TeamPage.vue";

const routes = [
	{
		path: "/",
		name: "Main",
		component: MainPage,
	},
	{
		path: "/About",
		name: "About",
		component: AboutPage,
	},
	{
		path: "/Case/:id",
		name: "Case",
		component: CasePage,
	},
	{
		path: "/CaseGroup/:id",
		name: "CaseGroup",
		component: CaseGroupPage,
	},
	{
		path: "/Clients",
		name: "Clients",
		component: ClientsPage,
	},
	{
		path: "/Contacts",
		name: "Contacts",
		component: ContactsPage,
	},
	{
		path: "/Equipment",
		name: "Equipment",
		component: EquipmentPage,
	},
	{
		path: "/Team",
		name: "Team",
		component: TeamPage,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to) {
		return new Promise((resolve) => {
			setTimeout(() => {
				if (to.hash) {
					console.log(to.hash);
					resolve({ el: to.hash });
				} else {
					resolve({ left: 0, top: 0 });
				}
			}, 700);
		});
	},
});

export default router;
