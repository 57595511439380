<template>
	<section class="cases">
		<case-component
			v-for="(c, k) in cases"
			:key="k"
			class="hidden-case"
			:id="'case-' + k"
			:ref="'case-' + k"
			:data="c"
		>
		</case-component>
	</section>
</template>

<script>
import CaseComponent from "./CaseComponent.vue";
import { ScrollAnimate } from "@/components/Services/ScrollAnimationService";
export default {
	components: { CaseComponent },
	props: {
		cases: {
			default: () => [],
		},
	},
	data() {
		return {
			animate: new ScrollAnimate(),
		};
	},
	methods: {
		checkAnimationsCases() {
			this.$nextTick(() => {
				for (const k in this.$refs) {
					this.animate.setObserverFunction([
						{
							name: "#" + k,
							animationName: "fade",
						},
					]);
				}
			});
		},
	},
	watch: {
		cases(newVal) {
			if (newVal.length > 0) {
				this.checkAnimationsCases();
			}
		},
	},
	mounted() {
		this.animate.init(this.$el);
		if (this.cases.length > 0) {
			this.checkAnimationsCases();
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/config.scss";
.cases {
	display: grid;
	gap: ptr(50px) ptr(30px);
	grid-template-columns: repeat(2, max-content);
	justify-self: center;
	margin-bottom: ptr(100px);
	padding-top: ptr(30px);
	justify-content: center;
}

.hidden-case {
	opacity: 0;
	&:nth-child(2n) {
		animation-delay: 0.1s;
	}
}
@media (max-width: 1024px) {
	.cases {
		gap: ptr(20px);
		grid-template-columns: max-content;
		margin-bottom: ptr(90px);
	}
}
</style>
