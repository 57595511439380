<template>
	<div class="tags-container" :class="{ white }">
		<div v-for="(item, index) in data" :key="index" class="tag">
			{{ item }}
		</div>
		<router-link
			:to="{ path: '/Contacts', hash: '#questions' }"
			v-if="cv"
			class="tag cv"
		>
			{{ $t("sendRequest") }}
		</router-link>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			default: () => [],
		},
		cv: {
			default: false,
		},
		white: {
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/config.scss";
.tags-container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: ptr(5px) 0;
	width: ptr(1423px);
}
.tag {
	border-radius: ptr(100px);
	text-transform: uppercase;
	border: 2px solid #882b02;
	color: #949494;
	display: flex;
	justify-content: center;
	padding: ptr(13px) ptr(15px);
	// flex-grow: 1;
	font-size: ptr(19px);
}
.white .tag {
	border: 2px solid rgba(255, 255, 255, 0.3);
}
.cv {
	background: #882b02;
	color: white;
	cursor: pointer;
}

@media (max-width: 1024px) {
	.tags-container {
		gap: ptr(3px) 0;
		width: ptr(320px);
	}
	.tag {
		padding: ptr(7px) ptr(10px);
		font-size: ptr(8px);
		// flex-grow: 1;
	}
}
</style>
