<template>
	<section class="phrase">
		<h1>
			{{ $t("team.h1") }}
		</h1>
	</section>
	<section class="squad">
		<div class="text-container">
			{{ $t("team.teamTxt") }}
		</div>
		<div class="content">
			<div
				class="img-mask"
				v-for="(p, k) in data.Komanda.Foto_komandy"
				:key="k"
				:id="'e-' + k"
			>
				<img :src="p" alt="" />
			</div>
			<p v-for="k in 6" :key="k" :id="'t-' + k">
				{{ $t(`team.textList[${k - 1}]`) }}
			</p>
			<span id="s" class="m"></span>
		</div>
	</section>
	<section class="cv">
		<h2>{{ $t("team.cv") }}</h2>
		<transition name="fade">
			<div v-if="send" class="thanks">
				<p class="fadeSpanList">
					<span v-for="(s, k) in $t('thanks')" :key="k">{{ s }}</span>
				</p>
			</div>
		</transition>
		<form>
			<label :err="form.Name">
				<span v-if="form.name.length === 0">{{ $t("form.name") }}</span>
				<input v-model="form.name" type="text" />
			</label>
			<label :err="form.Email">
				<span v-if="form.email.length === 0">{{
					$t("form.email")
				}}</span>
				<input v-model="form.email" type="text" />
			</label>
			<label class="file">
				{{ form.file?.name || $t("form.file") }}
				<input type="file" />
			</label>
			<button @click.prevent="sendCV">{{ $t("form.send") }}</button>
		</form>
	</section>
	<svg class="mask" width="0" hieght="0">
		<clipPath id="path" :style="`transform: scale(${scale})`">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M113.58 0.850281H124.42C151.233 0.850281 170.298 5.48676 186.53 14.1678C202.764 22.8496 215.501 35.5868 224.183 51.8204C232.864 68.0526 237.5 87.1172 237.5 113.93V124.771C237.5 151.583 232.864 170.648 224.183 186.88C215.501 203.114 202.764 215.851 186.53 224.533C170.298 233.214 151.233 237.85 124.42 237.85H113.58C86.7669 237.85 67.7022 233.214 51.4701 224.533C35.2365 215.851 22.4993 203.114 13.8175 186.88C5.13642 170.648 0.5 151.583 0.5 124.771V113.93C0.5 87.1172 5.13642 68.0526 13.8175 51.8204C22.4993 35.5868 35.2365 22.8496 51.4701 14.1678C67.7022 5.48676 86.7669 0.850281 113.58 0.850281Z"
			/>
		</clipPath>
	</svg>
	<section class="other-cases">
		<h2>{{ $t("case") }}</h2>
		<all-cases :cases="cases"></all-cases>
	</section>
	<div class="back-gradient"></div>
</template>

<script>
import { ScrollAnimate } from "@/components/Services/ScrollAnimationService.js";
import AllCases from "../../components/AllCases.vue";
import { http } from "../../components/Services/httpService";
export default {
	components: { AllCases },
	props: {
		data: {
			default: () => ({
				Komanda: {},
			}),
		},
		cases: {
			default: () => [],
		},
	},
	data() {
		return {
			teammate: ["/static/imgs/test.jpg"],
			scale: 1,
			form: {
				name: "",
				email: "",
				file: null,
			},
			send: false,
			animate: new ScrollAnimate(),
			error: {
				Name: "",
				Email: "",
			},
		};
	},
	methods: {
		sendCV() {
			this.error = {
				Name: "",
				Email: "",
			};
			const locale = localStorage.getItem("locale");
			const formData = new FormData();
			formData.append("name", this.form.name);
			formData.append("email", this.form.email);
			formData.append("file", this.form.file);
			formData.append("lang", locale || "ru-RU");
			formData.append("query", window.location.search);

			const gtagId = window.ga.getAll()?.[0]?.get('clientId');

			if (gtagId) {
				formData.append("gtagId", gtagId);
			}

			http.post("/api/cv?lang=" + locale, formData).then((response) => {
				if (response.status < 400) {
					window.dataLayer.push({'event': 'FormSubmit'});
					this.send = true;
					setTimeout(() => {
						this.send = false;
					}, 2000);
				} else {
					this.error = response.data.details;
				}
			}).catch(err => {
				console.error(err);

				if (err.response) {
					this.error = err.response.data.details;
				}
			});
		},
	},
	mounted() {
		this.animate.init();
		const ids = [
			...document.querySelector(".content").childNodes,
		].map((el) => ({ name: `#${el.id}`, animationName: "fade" }));
		this.animate.setObserverFunction([
			...ids,
			{
				name: ".cv",
				animationName: "fade",
			},
		]);
		const w = window.innerWidth;
		if (w < 1024) {
			this.scale = w / 468.75;
		} else {
			this.scale = 0.0005207100591715978 * w;
		}
		window.addEventListener("resize", () => {
			const w = window.innerWidth;
			if (w < 1024) {
				this.scale = w / 468.75;
			} else {
				this.scale = 0.0005207100591715978 * w;
			}
		});
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";

.mask {
	position: absolute;
}

.phrase {
	height: ptr(450px);
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}
h1 {
	font-size: ptr(50px);
	margin: 0;
	text-transform: uppercase;
}

.squad {
	justify-self: center;
	position: relative;
	z-index: 10;
}
.text-container {
	font-family: $sub-font;
	font-size: ptr(20px);
	white-space: pre-line;
	position: absolute;
	color: #949494;
	top: ptr(870px);
	left: ptr(350px);
}

.content {
	display: grid;
	justify-self: center;
	margin-top: ptr(240px);
	grid-template-columns: repeat(6, ptr(238px));
	p {
		font-family: $sub-font;
		width: ptr(238px);
		height: ptr(238px);
		display: flex;
		justify-content: center;
		white-space: pre;
		align-items: center;
		font-size: ptr(20px);
		text-transform: uppercase;
		text-align: center;
		opacity: 0;
		&:nth-child(15) {
			grid-column: 6;
		}
		&:nth-child(17) {
			grid-column: 6;
			grid-row: 4;
		}
		&:nth-child(18) {
			grid-column: 1;
			grid-row: 5;
		}
		&:nth-child(19) {
			grid-column: 3;
			grid-row: 6;
		}
		&:nth-child(19) {
			grid-column: 4;
			grid-row: 8;
		}
	}
}

.img-mask {
	opacity: 0;

	height: ptr(238px);
	width: ptr(238px);

	clip-path: url(#path);
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&:nth-child(1) {
		grid-column: 5;
	}
	&:nth-child(2) {
		grid-column: 1;
		grid-row: 2;
	}
	&:nth-child(3) {
		grid-column: 2;
		grid-row: 2;
	}
	&:nth-child(4) {
		grid-column: 4;
		grid-row: 2;
	}
	&:nth-child(5) {
		grid-column: 5;
		grid-row: 3;
	}
	&:nth-child(6) {
		grid-column: 4;
		grid-row: 4;
	}
	&:nth-child(7) {
		grid-column: 5;
		grid-row: 4;
	}
	&:nth-child(8) {
		grid-column: 2;
		grid-row: 5;
	}
	&:nth-child(8) {
		grid-column: 2;
		grid-row: 5;
	}
	&:nth-child(9) {
		grid-column: 3;
		grid-row: 5;
	}
	&:nth-child(10) {
		grid-column: 4;
		grid-row: 6;
	}
	&:nth-child(11) {
		grid-column: 5;
		grid-row: 6;
	}
	&:nth-child(12) {
		grid-column: 4;
		grid-row: 7;
	}
	&:nth-child(13) {
		grid-column: 4;
		grid-row: 9;
	}
	&:nth-child(14) {
		grid-column: 5;
		grid-row: 9;
	}
}

.cv {
	width: ptr(1423px);
	margin: ptr(272px) 0 0;
	justify-self: center;
	opacity: 0;
	h2 {
		font-size: ptr(34px);
		text-transform: uppercase;
		text-align: center;
	}
}

form {
	margin-top: ptr(60px);
	padding: ptr(84px) 0;
	position: relative;
	justify-content: center;
	display: grid;
	grid-template-columns: repeat(4, max-content);
	gap: ptr(14px) ptr(12px);
	input {
		background: rgba(255, 255, 255, 0.07);
	}
	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 0;
		width: ptr(1423px);
		height: 1px;
		background: rgba(255, 255, 255, 0.3);
	}
	&::before {
		top: 0;
	}
	&::after {
		bottom: 0;
	}
}
button {
	background: white;
	color: black;
	width: ptr(307px);
	padding: ptr(20px) 0;
	border-radius: ptr(22px);
	cursor: pointer;
	transition: $trs;
	&:hover {
		background: $sub-color;
		color: white;
	}
}
input,
.file {
	border-radius: ptr(22px);
	color: white;
	border: 1px solid rgba(255, 255, 255, 0.15);
	box-sizing: border-box;
	padding: ptr(20px) ptr(49px);
	transition: $trs;
	font-family: $sub-font;
	width: ptr(307px);
	font-size: ptr(14px);
	cursor: pointer;
	&::placeholder {
		color: white;
	}
	&:hover {
		border: 1px solid rgba(255, 255, 255, 0.25);
	}
	&:focus {
		outline: none;
		border: 1px solid rgba(255, 255, 255, 0.4);
	}
}
.file input {
	display: none;
}

.m {
	display: none;
}

label {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	&::before {
		content: attr(err);
		left: ptr(15px);
		top: ptr(-25px);
		position: absolute;
		font-size: ptr(16px);
		color: white;
		font-family: $sub-font;
	}
	span {
		position: absolute;
		pointer-events: none;
		content: attr(placename);
		font-family: $sub-font;
		font-size: ptr(14px);
		transition: 0.2s $anmTrs;
	}
	&:focus-within {
		span {
			opacity: 0;
		}
	}
}

@media (max-width: 1024px) {
	.phrase {
		height: ptr(268px);
	}
	h1 {
		font-size: ptr(23px);
	}

	.text-container {
		font-size: ptr(14px);
		text-align: center;
		top: ptr(830px);
		left: ptr(64px);
	}

	.content {
		display: grid;
		justify-self: center;
		margin-top: ptr(137px);
		grid-template-columns: repeat(2, ptr(161px));
		p {
			width: 100%;
			height: ptr(70px);
			white-space: normal;
			font-size: ptr(14px);
			display: flex;
			&:nth-child(15) {
				grid-column: span 2;
				align-items: flex-end;
				grid-row: 2;
				padding-bottom: ptr(14px);
			}
			&:nth-child(16) {
				align-items: flex-end;
				grid-column: span 2;
				grid-row: 9;
				padding-bottom: ptr(14px);
			}
			&:nth-child(17) {
				align-items: flex-end;
				padding-bottom: ptr(14px);
				grid-column: span 2;
				grid-row: 14;
			}
			&:nth-child(18) {
				align-items: flex-start;
				grid-column: span 2;
				grid-row: 3;
			}
			&:nth-child(19) {
				align-items: flex-start;
				grid-column: span 2;
				grid-row: 10;
			}
			&:nth-child(20) {
				align-items: flex-start;
				grid-column: span 2;
				grid-row: 15;
			}
		}
	}

	.img-mask {
		width: ptr(161px);
		height: ptr(161px);
		&:nth-child(1) {
			grid-column: 1;
		}
		&:nth-child(2) {
			grid-row: 1;
			grid-column: 2;
		}
		&:nth-child(3) {
			grid-column: 1;
			grid-row: 4;
		}
		&:nth-child(4) {
			grid-column: 2;
			grid-row: 4;
		}
		&:nth-child(5) {
			grid-column: 1;
			grid-row: 5;
		}
		&:nth-child(6) {
			grid-column: 2;
			grid-row: 5;
		}
		&:nth-child(7) {
			grid-column: 1;
			grid-row: 7;
		}
		&:nth-child(8) {
			grid-column: 2;
			grid-row: 7;
		}
		&:nth-child(9) {
			grid-column: 1;
			grid-row: 8;
		}
		&:nth-child(10) {
			grid-column: 2;
			grid-row: 11;
		}
		&:nth-child(11) {
			grid-column: 1;
			grid-row: 12;
		}
		&:nth-child(12) {
			grid-column: 2;
			grid-row: 12;
		}
		&:nth-child(13) {
			grid-column: 1;
			grid-row: 13;
		}
		&:nth-child(14) {
			grid-column: 2;
			grid-row: 13;
		}
	}

	.m {
		display: block;
		height: ptr(200px);
		grid-row: 6;
		grid-column: span 2;
	}

	.cv {
		width: ptr(320px);
		margin: ptr(187px) 0 ptr(84px);
	}

	h2 {
		font-size: ptr(23px);
	}

	form {
		margin-top: 0;
		padding-top: ptr(45px);
		grid-template-columns: repeat(1, max-content);
		gap: ptr(6px);
		&::before {
			content: none;
		}
	}
}
</style>
