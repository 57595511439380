<template>
	<section class="group-cases">
		<h1>
			{{ caseGroup.name }}
		</h1>
		<all-cases :cases="caseGroup.cases"></all-cases>
	</section>
	<section class="other-cases">
		<h2>{{ $t("otherCase") }}</h2>
		<all-cases :cases="filteredCases"></all-cases>
	</section>
</template>

<script>
import AllCases from "../../components/AllCases.vue";
import { http } from "../../components/Services/httpService";
export default {
	components: { AllCases },
	props: {
		cases: {
			default: () => [],
		},
	},
	data() {
		return {
			caseGroup: {
				id: 0,
				name: "",
				cases: [],
			},
			filteredCases: [],
		};
	},
	methods: {
		getCaseGroup(id) {
			http.get(
				`/api/case-groups?id=${id}&lang=${this.$i18n.locale}`
			).then((response) => {
				if (response.status < 400) {
					this.caseGroup = response.data;
					this.filteredCases = this.cases.filter(
						(c) =>
							response.data.cases.findIndex(
								(ac) => ac.name === c.name
							) < 0
					);
				}
			});
		},
	},
	mounted() {
		this.getCaseGroup(this.$route.params.id);
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";

.group-cases {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: ptr(36px);
}

h1 {
	font-size: ptr(34px);
	text-transform: uppercase;
	margin: 0 0 ptr(20px);
}

@media (max-width: 1024px) {
	.group-cases {
		margin-top: ptr(156px);
	}
	h1 {
		font-size: ptr(24px);
	}
}
</style>
