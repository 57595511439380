<template>
	<section class="phrase">
		<a class="phone" href="tel:+74951365502" @click="onPhoneClick">
			<h1>
				+7 495 136-55-02
			</h1>
		</a>
		<a class="email" href="mailto:info@magnitofon.pro" @click="onEmailClick">
			<p>info@magnitofon.pro</p>
		</a>
	</section>
	<section class="maps">
		<div
			v-for="(m, k) in maps"
			:key="k"
			class="map"
			@click="openMap(m.url)"
		>
			<svg class="border" viewBox="0 0 349 349" fill="none">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M193.834 1.01294C237.919 1.01294 260.89 6.95651 281.828 18.1543C302.717 29.3262 319.111 45.7205 330.283 66.6101C341.481 87.5482 347.425 110.519 347.425 154.604V194.097C347.425 238.182 341.481 261.153 330.283 282.091C319.111 302.98 302.717 319.375 281.828 330.546C260.89 341.744 237.919 347.688 193.834 347.688H154.341C110.256 347.688 87.2852 341.744 66.3471 330.546C45.4576 319.375 29.0633 302.98 17.8914 282.091C6.69362 261.153 0.75 238.182 0.75 194.097V154.604C0.75 110.519 6.69362 87.5482 17.8914 66.6101C29.0633 45.7205 45.4576 29.3262 66.3471 18.1543C87.2852 6.95651 110.256 1.01294 154.341 1.01294H193.834Z"
				/>
			</svg>
			<div class="adress">
				<p>
					{{ m.city }}
				</p>
				<span>
					{{ m.adress }}
				</span>
			</div>
		</div>
	</section>
	<section class="questions" id="questions">
		<h2>{{ $t("question") }}</h2>
		<transition name="fade">
			<div v-if="send" class="thanks">
				<p class="fadeSpanList">
					<span v-for="(s, k) in $t('thanks')" :key="k">{{ s }}</span>
				</p>
			</div>
		</transition>
		<form>
			<label :err="error.Name">
				<span v-if="form.name.length === 0">
					{{ $t("form.name") }}
				</span>
				<input @focus="clearErrors" v-model="form.name" type="text" />
			</label>
			<label :err="error.Email">
				<span v-if="form.email.length === 0">
					{{ $t("form.email") }}
				</span>
				<input v-model="form.email" type="email" @focus="clearErrors" />
			</label>
			<label :err="error.Phone">
				<span v-if="form.phone.length === 0">
					{{ $t("form.phone") }}
				</span>
				<input
					v-model="form.phone"
					v-maska="'+#(###)###-##-##'"
					type="text"
					@focus="clearErrors"
				/>
			</label>
			<label :err="error.Question" class="textarea">
				<span v-if="form.question.length === 0">
					{{ $t("form.text") }}
				</span>
				<textarea
					v-model="form.question"
					@focus="clearErrors"
				></textarea>
			</label>
			<label class="file">
				<p>
					{{ form.file?.name || $t("form.file") }}
				</p>
				<input @change="getFile($event.target)" type="file" />
			</label>
			<button @click.prevent="sendQuestion">{{ $t("form.send") }}</button>
		</form>
	</section>
	<section class="other-cases">
		<h2>{{ $t("case") }}</h2>
		<all-cases :cases="cases"></all-cases>
	</section>
	<div class="back-gradient"></div>
</template>

<script>
import AllCases from "../../components/AllCases.vue";
import { http } from "../../components/Services/httpService";
import { reachGoal } from "../../analytics";
export default {
	components: { AllCases },
	props: {
		cases: {
			default: () => [],
		},
	},
	data() {
		return {
			maps: [
				{
					city: this.$t("maps.moscow.city"),
					adress: this.$t("maps.moscow.address"),
					url: "https://goo.gl/maps/AU6uM477ZeTpHzgs5",
				},
				{
					city: this.$t("maps.spb.city"),
					adress: this.$t("maps.spb.address"),
					url: "https://goo.gl/maps/xb7crca6kSzgjMYB9",
				},
				{
					city: this.$t("maps.guang.city"),
					adress: this.$t("maps.guang.address"),
					url: "https://goo.gl/maps/xb7crca6kSzgjMYB9",
				},
			],
			form: {
				name: "",
				email: "",
				phone: "",
				question: "",
				file: null,
			},
			scale: 1,
			send: false,
			error: {
				Name: "",
				Email: "",
				Phone: "",
				Question: "",
			},
		};
	},
	methods: {
        onPhoneClick() {
            reachGoal("mag_phone");
        },
        onEmailClick() {
            reachGoal("mag_email");
        },
		openMap(url) {
			window.open(url);
		},
		clearErrors() {
			this.error = {
				Name: "",
				Email: "",
				Phone: "",
				Question: "",
			};
		},
		getFile(target) {
			this.form.file = target.files[0];
		},
		sendQuestion() {
			this.error = {
				Name: "",
				Email: "",
				Phone: "",
				Question: "",
			};

			const locale = localStorage.getItem("locale");
			const formData = new FormData();
			formData.append("name", this.form.name);
			formData.append("email", this.form.email);
			formData.append("phone", this.form.phone);
			formData.append("question", this.form.question);
			formData.append("file", this.form.file);
			formData.append("lang", locale || "ru-RU");
			formData.append("query", window.location.search);

			const gtagId = window.ga.getAll()?.[0]?.get('clientId');

			if (gtagId) {
				formData.append("gtagId", gtagId);
			}

			http.post("/api/question", formData).then((response) => {
				if (response.status < 400) {
					window.dataLayer.push({'event': 'FormSubmit'});
					this.send = true;
					setTimeout(() => {
						this.send = false;
					}, 2000);
				} else {
					this.error = response.data.details;
				}
			}).catch(err => {
				console.error(err);

				if (err.response) {
					this.error = err.response.data.details;
				}
			});
		},
	},
	mounted() {
		const w = window.innerWidth;
		if (this.$route.hash) {
			const el = document.querySelector(this.$route.hash);
			window.scrollTo(0, el.offsetTop);
		}
		if (w < 1024) {
			this.scale = w / 376.08069164265135;
		} else {
			this.scale = 0.0005207100591715978 * w;
		}
		window.addEventListener("resize", () => {
			const w = window.innerWidth;
			if (w < 1024) {
				this.scale = w / 376.08069164265135;
			} else {
				this.scale = 0.0005207100591715978 * w;
			}
		});
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";

.phrase {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	height: ptr(493px);
}
.phone h1 {
	font-size: ptr(50px);
	margin: 0;
}
.email {
	margin-top: ptr(20px);
	font-size: ptr(21px);
}

.maps {
	display: flex;
	gap: ptr(15px);
	justify-content: center;
	align-items: center;
	margin-top: ptr(200px);
}

.map {
	width: ptr(452px);
	height: ptr(452px);
	cursor: pointer;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: center;
	&:hover svg path {
		stroke: $sub-color;
	}
}

.adress {
	font-family: $sub-font;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	p {
		text-transform: uppercase;
		font-size: ptr(20px);
		margin-bottom: ptr(11px);
	}
	span {
		width: ptr(300px);
		text-align: center;
		top: 100%;
		position: absolute;
		font-size: ptr(18px);
		color: #646464;
	}
}

.border {
	position: absolute;
	pointer-events: none;
	width: 100%;
	height: 100%;
	z-index: 2;
	path {
		x: 1;
		y: 1;
		width: ptr(452px);
		height: ptr(452px);
		transition: $trs;
		stroke-width: 1.5;
		stroke: #404040;
	}
}

#path {
	path {
		transform: scale(1.297);
	}
}

.socials {
	display: flex;
	justify-self: center;
	gap: ptr(3px);
	margin-top: ptr(97px);
	a {
		border-radius: ptr(100px);
		display: flex;
		align-items: center;
		justify-content: center;
		width: ptr(212px);
		height: ptr(57px);
		background: rgba(255, 255, 255, 0.3);
		color: #d7d6d6;
		text-transform: uppercase;
		cursor: pointer;
		font-size: ptr(16px);
		transition: $trs;
		&:hover {
			background: rgba(255, 255, 255, 0.2);
		}
	}
}

.questions {
	margin: ptr(200px) 0 0;
	width: ptr(1423px);
	justify-self: center;
	padding-top: ptr(23px);
	h2 {
		font-size: ptr(34px);
		text-align: center;
		text-transform: uppercase;
		margin: 0;
	}
}

form {
	margin-top: ptr(60px);
	padding: ptr(84px) 0;
	position: relative;
	justify-content: center;
	display: grid;
	grid-template-columns: repeat(3, max-content);
	gap: ptr(14px) ptr(12px);
	input,
	textarea {
		border-radius: ptr(22px);
		background: rgba(255, 255, 255, 0.07);
		color: white;
		border: 1px solid rgba(255, 255, 255, 0.15);
		box-sizing: border-box;
		padding: ptr(20px) ptr(49px);
		transition: $trs;
		font-family: $sub-font;
		font-size: ptr(14px);
		&:hover {
			border: 1px solid rgba(255, 255, 255, 0.25);
		}
		&:focus {
			outline: none;
			border: 1px solid rgba(255, 255, 255, 0.4);
		}
	}
	label {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		span {
			position: absolute;
			pointer-events: none;
			content: attr(placename);
			font-size: ptr(14px);
			font-family: $sub-font;
			transition: 0.2s $anmTrs;
		}
		&::before {
			content: attr(err);
			left: ptr(15px);
			top: ptr(-25px);
			position: absolute;
			font-size: ptr(16px);
			color: white;
			font-family: $sub-font;
		}
		&:focus-within {
			span {
				opacity: 0;
			}
		}
	}
	input {
		width: ptr(307px);
	}
	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 0;
		width: ptr(1423px);
		height: 1px;
		background: rgba(255, 255, 255, 0.3);
	}
	&::before {
		top: 0;
	}
	&::after {
		bottom: 0;
	}
}
.textarea {
	grid-column: span 3;
	textarea {
		resize: none;
		height: ptr(110px);
		width: 100%;
	}
	span {
		left: ptr(50px);
		top: ptr(20px);
	}
}
button {
	background: white;
	color: black;
	border-radius: ptr(22px);
	height: ptr(57px);
	cursor: pointer;
	transition: $trs;
	&:hover {
		background: $sub-color;
		color: white;
	}
}
.file {
	border-radius: ptr(22px);
	background: rgba(255, 255, 255, 0.07);
	color: white;
	border: 1px solid rgba(255, 255, 255, 0.15);
	text-align: center;
	padding: ptr(20px) ptr(49px);
	font-family: $sub-font;
	font-size: ptr(14px);
	grid-column: 2;
	cursor: pointer;
	transition: $trs;
	&:hover {
		border: 1px solid rgba(255, 255, 255, 0.25);
	}
	input {
		display: none;
	}
}

@media (max-width: 1024px) {
	.phrase {
		height: ptr(279px);
	}
	.phone h1 {
		font-size: ptr(23px);
	}
	.email {
		margin-top: ptr(11px);
		font-size: ptr(10px);
	}

	.maps {
		gap: 0;
		margin-top: ptr(108px);
		flex-direction: column;
	}

	.map {
		width: ptr(320px);
		height: ptr(320px);
		p {
			margin-bottom: ptr(5px);
		}
		span {
			font-size: ptr(12px);
		}
	}

	.border path {
		width: ptr(320px);
		height: ptr(320px);
	}

	#path {
		path {
			transform: scale(1.079);
		}
	}

	.socials {
		flex-direction: column;
		gap: ptr(10px);
		margin-top: ptr(91px);
	}

	.questions {
		margin-top: ptr(162px);
		width: ptr(320px);
		h2 {
			font-size: ptr(23px);
		}
	}

	form {
		margin-top: 0;
		padding-top: ptr(52px);
		grid-template-columns: repeat(1, max-content);
		gap: ptr(6px);
		input,
		textarea {
			border-radius: ptr(22px);
			padding: ptr(19px) ptr(20px);
		}
		&::before,
		&::after {
			content: none;
		}
	}
	.textarea {
		grid-column: span 1;
		textarea {
			height: ptr(154px);
		}
		span {
			left: ptr(20px);
		}
	}
	.file {
		grid-column: auto;
	}
}
</style>
