import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import Maska from "maska";
import VueYandexMetrika from "./components/metrika";
import VueGtag, { trackRouter } from "vue-gtag-next";

const i18n = createI18n({
	locale: "ru-RU",
	fallbackLocale: "ru-RU",
	messages: {
		"ru-RU": {
			pages: {
				equipment: "Оборудование",
				about: "О нас",
				contacts: "Контакты",
				clients: "Клиенты",
				team: "Команда",
			},
			form: {
				name: "Ваше имя",
				email: "Ваша почта",
				phone: "Ваш телефон",
				text: "Ваш вопрос",
				file: "+ Прикрепить файл",
				send: "Отправить",
			},
			main: {
				h1: "МЫ СОЗДАЁМ <br/> <span class='clr'>ЭМОЦИИ</span>",
			},
			case: "Кейсы",
			otherCase: "Другие кейсы",
			about: {
				h1:
					"Создаём <span class='audio'>аудио-визуальную</span> <br /> концепцию мероприятий <br /> и обеспечиваем её <span class='tech'>техническую</span> <br />реализацию",
				circles: [
					"<span class='heavy'>интегрируя</span> CGI-искусство <br /> в ивенты",
					"<span class='heavy'>создавая</span> аудионаполнение",
					"<span class='heavy'>выстраивая</span> световой сценарий <br /> мероприятий",
				],
				generalParters: "Генеральный партнёр",
				partners: ["Concert\nvenue"],
			},
			equipment: {
				h1: "Оборудование",
				opportunities: "Возможности",
				techProd:
					"Наши партнеры в США и Европе помогают нам разрабатывать инновационные решения в сфере <span>Технического продакшена</span> мероприятий",
				textBlock: {
					p1:
						"Ежегодно мы посещаем международные выставки и устанавливаем прямые контакты с производителями, первыми получая новые модели оборудования.",
					p2:
						"Парк оборудования имеет регулярный цикл обновляемости, пополняясь новыми позициями.",
				},
				park:
					"Наш парк оборудования состоит из серийных и <span> индивидуально произведенных </span><br class='desk'> моделей оборудования наших партнёров:",
			},
			team: {
				h1: "Команда",
				textList: [
					"CGI-\nХУДОЖНИКИ",
					"ХУДОЖНИКИ\nПО СВЕТУ",
					"АРХИТЕКТУРНЫЕ\nИНЖЕНЕРЫ",
					"ЗВУКО-\nРЕЖИССЕРЫ",
					"ВИДЕО-\nИНЖЕНЕРЫ",
					"КОНТЕНТ-\nДИЗАЙНЕРЫ",
				],
				teamTxt:
					"Ежегодно наши технические\nспециалисты проходят\nобучение на знание продукта\nв командах производителей\nоборудования.",
				cv: "Стань частью команды",
			},
			question: "Оставить заявку",
			stars: "Исполнители",
			sendRequest: "Оставить заявку",
			policy: "Политика конфиденциальности",
			rights: "Все права защищены",
			maps: {
				moscow: {
					city: "Москва",
					address: "Ленинская Слобода 26 с15",
				},
				spb: {
					city: "Санкт Петербург",
					address: "Арсенальная набережная, 1",
				},
				guang: {
					city: "Guǎngzhōu",
					address: "28 Zhujiang E Rd, Tianhe Guangzhou",
				},
			},
			thanks: "Спасибо за заявку!",
		},
		"en-US": {
			pages: {
				equipment: "Equipment",
				about: "About",
				contacts: "Contacts",
				clients: "Clients",
				team: "Team",
			},
			form: {
				name: "Your name",
				email: "Your email",
				phone: "Your phone",
				text: "Your question",
				file: "+ Add file",
				send: "Send",
			},
			main: {
				h1: "WE CREATE <br/> <span class='clr'>EMOTIONS</span>",
			},
			case: "Cases",
			otherCase: "Other Cases",
			about: {
				h1:
					"We create<span class='audio' >audio-visual</span> <br /> concept of events<br /> and provide its<span class='tech'>technical</span> <br />implementation",
				circles: [
					"<span class='heavy'>INTEGRATING</span> CGI ART<br />INTO EVENTS",
					"<span class='heavy'>creating</span> audio content",
					"<span class='heavy'>building</span> a light scenario of<br /> events",
				],
				generalParters: "General Partners",
			},
			equipment: {
				h1: "Equipment",
				opportunities: "Opportunities",
				techProd:
					"OUR PARTNERS IN USA AND EUROPE HELP US DEVELOP INNOVATIVE SOLUTIONS IN THE FIELD OF <span>TECHNICAL PRODUCTION</span> OF EVENTS",
				textBlock: {
					p1:
						"Every year we visit international exhibitions and establish direct contacts with manufacturers, being the first to receive new models of equipment.",
					p2:
						"The equipment fleet has a regular updating cycle, replenished with new positions.",
				},
				park:
					"Our equipment fleet consists of serial<br class='desk'> and <span> individually manufactured </span> <br class='desk'> models of<br class='desk'> our partners equipment:",
			},
			team: {
				h1: "Team",
				textList: [
					"CGI\nARTISTS",
					"LIGHTING\nARTISTS",
					"ARCHITECTURAL\nENGINEERS",
					"SOUND\nENGINEERS",
					"VIDEO\nENGINEERS",
					"CONTENT\nDESIGNERS",
				],
				teamTxt:
					"Every year our technical\nspecialists are trained\nto know the product\nin the teams of equipment\nmanufacturers.",
				cv: "For your cv",
			},
			question: "for your questions",
			stars: "Stars",
			sendRequest: "Leave request",
			policy: "Privacy Policy",
			rights: "All rights reserved",
			maps: {
				moscow: {
					city: "Moscow",
					address: "Deninskaya Sloboda 26s15",
				},
				spb: {
					city: "SAINT PETERSBURG",
					address: "Arsenalnaya embankment 1",
				},
				guang: {
					city: "Guǎngzhōu",
					address: "28 Zhujiang E Rd, Tianhe Guangzhou",
				},
			},
			thanks: "Thank you for the application!",
		},
	},
});

const app = createApp(App);

if (process.env.NODE_ENV === "production") {
	app.use(
		VueGtag,
		{
			property: { id: "UA-157910480-2" },
		}
	);

	trackRouter(router);

	app.use(VueYandexMetrika, {
		id: 86496633,
		router: router,
		env: process.env.NODE_ENV,
		clickmap: true,
		trackLinks: true,
		accurateTrackBounce: true,
		webvisor: true,
	});
}

app.use(i18n)
	.use(router)
	.use(Maska)
	.mount("#app");
