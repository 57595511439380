export class ScrollAnimate {
	paralaxElements = {};
	observerElements = {};
	ctx = null;
	fontSize = null;
	observer = null;
	context = null;

	smoothScrlAnm(root, slc, type, val) {
		if (!(slc in this.paralaxElements)) {
			if (root.querySelector(slc) !== null) {
				this.paralaxElements[slc] = root.querySelector(slc);
			}
		}
		if (this.paralaxElements[slc] !== undefined) {
			this.paralaxElements[slc].style[type] = val;
		}
	}
	setObserverFunction(elements) {
		for (let el of elements) {
			const htmlEl = this.context.querySelector(el.name);
			if (!htmlEl) continue;
			htmlEl.setAttribute("animate-id", el.name);
			this.observerElements[el.name] = el;
			this.observer.observe(htmlEl);
		}
	}
	parseTxt(text, length, size) {
		if (!this.ctx) {
			this.ctx = this.context.createElement("canvas").getContext("2d");
		}
		if (!this.fontSize) {
			this.fontSize = parseFloat(
				window.getComputedStyle(document.documentElement).fontSize
			);
		}
		const adativeLength = (length / 16) * this.fontSize;
		this.ctx.font = `${(size / 16) *
			this.fontSize}px SF Pro Display, sans-serif`;
		const checkWidh = (txt) => {
			let res = 0;
			for (let t of txt) {
				res += this.ctx.measureText(t).width;
			}
			return res <= adativeLength;
		};
		const txt = text
			.replaceAll(/\n\n/g, " /~1 ")
			.replaceAll(/\n/g, " /~ ")
			.split(" ");
		const res = [];
		let ch = 0;
		for (let t of txt) {
			if (res[ch] === undefined) {
				res[ch] = t + " ";
			} else if (t !== "/~1" && t !== "/~" && checkWidh(res[ch] + t)) {
				res[ch] = res[ch] + t + " ";
			} else if (t === "/~1") {
				ch += 2;
				res[ch] = "\n";
			} else if (t === "/~") {
				ch += 1;
			} else {
				ch += 1;
				res[ch] = t + " ";
			}
		}
		return res;
	}
	init(context) {
		const options = {
			root: null,
			rootMargin: `20% 0% -20%`,
			threshold: 0,
		};
		this.context = context || document;
		const callback = (entries) => {
			entries.forEach((e) => {
				if (e.isIntersecting) {
					const atr = e.target.getAttribute("animate-id");
					const obj = this.observerElements[atr];
					setTimeout(() => {
						e.target.classList.add(obj.animationName);
					}, obj.delay);
				}
			});
		};
		this.observer = new IntersectionObserver(callback, options);
	}
}
